import greensterLogo from "@kiosk/front/assets/img/greenster.svg"

import { clientConfig } from "../config"

import kioskLogo from "./logo.svg"

interface LogoProps {
  readonly height?: number
}

export default function Logo(props: LogoProps) {
  const logo = clientConfig.logo === "kiosk" ? kioskLogo : greensterLogo

  return <img height={props.height ?? 28} src={logo} />
}
